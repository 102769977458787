<template>
    <div>
        <div class="header">
            <van-nav-bar title="订单管理" :border="false" left-arrow @click-left="onClickLeft" />
            <van-tabs v-model="active" @click="change" color="#3861FB" title-active-color="#3861FB"
                line-width="20.3562vw">
                <van-tab title="未付款"></van-tab>
                <van-tab title="已付款"></van-tab>
                <van-tab title="已完成"></van-tab>
                <van-tab title="已取消"></van-tab>
                <van-tab title="申诉中"></van-tab>
            </van-tabs>
        </div>
        <van-empty description="暂无数据" v-if="orderList.length == 0" />
        <div style="background-color: #fff;padding-top: 5.0891vw" v-else>
            <van-list v-model:loading="loading" :offset="10" :immediate-check="false" :finished="finished"
                finished-text="没有更多了" loading-text="加载中..." @load="onLoad">
                <div class="orderBox" v-for="item in orderList" @click="goDetail(item.orderSn)">
                    <div style="display: flex;align-items: center">
                        <img src="../../assets/img/avatar.png" alt=""
                            style="width: 10.1781vw;height: 10.1781vw;margin-right: 2.5445vw">
                        <div>
                            <div class="orderText">{{ item.name.slice(0, 7) }}</div>
                            <div class="orderText">{{ Number(String(item.amount).replace(/^(.*\..{4}).*$/, "$1")) }}USDT
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="coin">{{ statusType[item.status] }}</div>
                        <div class="orderText" style="margin-top: 1.0178vw;text-align: center;">{{ item.money }}CNY
                        </div>
                    </div>
                </div>
                <div style="width: 100%;height: 1px;"></div>
            </van-list>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            orderList: [],
            pageNo: 1,
            pageSize: 10,
            loading: false,
            finished: false,
            total: 0,
            status: 1,
            statusType: {
                0: '已取消',
                1: '等待付款',
                2: '已付款',
                3: '交易完成',
                4: '申诉中'
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        change(val) {
            this.loading = false
            this.orderList = []
            this.pageNo = 1
            if (val == 0) {
                this.status = 1
                this.getList()
            } else if (val == 1) {
                this.status = 2
                this.getList()
            } else if (val == 2) {
                this.status = 3
                this.getList()
            } else if (val == 3) {
                this.status = 0
                this.getList()
            } else if (val == 4) {
                this.status = 4
                this.getList()
            }
        },
        //获取订单
        async getList() {
            this.loading = false
            let data = {
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                status: this.status
            }
            const res = await this.$http.post(this.host + '/otc/order/self', data)
            if (this.orderList.length > 0) {
                this.orderList.push(...res.data.data.content)
            } else {
                this.orderList = res.data.data.content
            }
            this.total = res.data.data.totalElements
        },
        goDetail(id) {
            this.$router.push({
                path: "/otcDetail",
                query: {
                    orderNumber: id
                }
            })
        },
        onLoad() {
            // 异步更新数据
            setTimeout(() => {
                if (this.orderList.length >= this.total) {
                    this.finished = true
                    return
                }
                this.pageNo++
                this.getList()
            }, 1000)
        },
    }
}
</script>

<style scoped>
.orderBox {
    width: 340px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 8.1425vw;
}

.coin {
    width: 20.3562vw;
    height: 6.3613vw;
    font-size: 3.0534vw;
    color: #fff;
    text-align: center;
    line-height: 7.1247vw;
    background: rgba(22, 199, 132, 1)
}

.orderText {
    font-size: 3.8168vw;
    color: #3861FB
}

.header {
    background-color: #fff;
    border-bottom: .2545vw solid #eee
}

.van-nav-bar {
    background-color: transparent !important;
}

::v-deep .van-icon {
    color: #000;
}

::v-deep .van-nav-bar__title {
    font-family: SF Pro;
    font-size: 4.0712vw;
    font-weight: 510;
}
</style>